<div class="root container-alt">
  <div class="content">
    <div class="wrapper">
      <div class="logo">
        <img src="/assets/images/icons-trudi.svg" alt="">
      </div>
      <ng-container *ngIf="!isSaved; else saved">
        <div class="content-text">
          <div class="text-header">
            Email Notification Settings
          </div>
          <div class="text-sub-header">
            Let's take a moment to update your email communication preferences.
          </div>
        </div>
        <div class="checkbox-block">
          <div class="block-header">
            Property Emails:
          </div>
    
          <div class="checkbox-item">
            <div class="check-box">
              <input
                id="cb-newconv"
                class="def-checkbox"
                type="checkbox"
                [formControl]="fg.controls.newConversation"
                (change)="onCheckboxOther($event)"
              />
              <label for="cb-newconv" class="checkbox-label">
                <img [src]="getNewConversationValue ? '/assets/images/checkbox-on.svg' : '/assets/images/checkbox-off.svg'" class="user-checkbox checkbox-head">
              </label>
            </div>
            <div class="item-text">
              <div class="item-header">
                New Conversation
              </div>
              <div class="item-sub-header">
                Sent when a new conversation has been started with you about your property.
              </div>
            </div>
          </div>
    
          <div class="checkbox-item">
            <div class="check-box">
              <input
                id="cb-newReply"
                class="def-checkbox"
                type="checkbox"
                [formControl]="fg.controls.newReply"
                (change)="onCheckboxOther($event)"
              />
              <label for="cb-newReply" class="checkbox-label">
                <img [src]="getNewReplyValue ? '/assets/images/checkbox-on.svg' : '/assets/images/checkbox-off.svg'" class="user-checkbox checkbox-head">
              </label>
            </div>
            <div class="item-text">
              <div class="item-header">
                New Reply
              </div>
              <div class="item-sub-header">
                Sent when you have received a reply to a message about your property.
              </div>
            </div>
          </div>
    
        </div>
        <div class="checkbox-block">
          <div class="block-header">
            Marketing Emails:
          </div>
    
          <div class="checkbox-item">
            <div class="check-box">
              <input
                id="cb-updates"
                class="def-checkbox"
                type="checkbox"
                [formControl]="fg.controls.updates"
                (change)="onCheckboxOther($event)"
              />
              <label for="cb-updates" class="checkbox-label">
                <img [src]="getUpdatesValue ? '/assets/images/checkbox-on.svg' : '/assets/images/checkbox-off.svg'" class="user-checkbox checkbox-head">
              </label>
            </div>
            <div class="item-text">
              <div class="item-header">
                Updates
              </div>
              <div class="item-sub-header">
                Relevant news, tips and resources from Trudi.
              </div>
            </div>
          </div>
    
          <div class="checkbox-item">
            <div class="check-box">
              <input
                id="cb-promotions"
                class="def-checkbox"
                type="checkbox"
                [formControl]="fg.controls.promotions"
                (change)="onCheckboxOther($event)"
              />
              <label for="cb-promotions" class="checkbox-label">
                <img [src]="getPromotionsValue ? '/assets/images/checkbox-on.svg' : '/assets/images/checkbox-off.svg'" class="user-checkbox checkbox-head">
              </label>
            </div>
            <div class="item-text">
              <div class="item-header">
                Promotions
              </div>
              <div class="item-sub-header">
                Occasional offers relevant to you (please note we do not sell your personal information).
              </div>
            </div>
          </div>
    
          <div class="checkbox-item unsub">
            <div class="check-box">
              <input
                id="cb-unsubscribeAll"
                class="def-checkbox"
                type="checkbox"
                [formControl]="fg.controls.unsubscribeAll"
                (change)="onCheckboxUnsubscribeAll($event)"
              />
              <label for="cb-unsubscribeAll" class="checkbox-label">
                <img [src]="getUnsubscribeAllValue ? '/assets/images/checkbox-on.svg' : '/assets/images/checkbox-off.svg'" class="user-checkbox checkbox-head">
              </label>
            </div>
            <div class="checkbox-text">
              Unsubscribe from all email notifications.
            </div>
          </div>
    
        </div>
      </ng-container>
      <ng-template #saved>
        <div class="thank-text">
          Thanks, your email notification preferences have been successfully updated.
        </div>
      </ng-template>
  
      <div class="button-wrapper">
        <button *ngIf="!isSaved" type="button" class="button-inner green" (click)="savePreferences()">
          <span class="text">
            Save my preferences
          </span>
        </button>
        <button *ngIf="isSaved" [ngClass]="{'top-space': isSaved}" type="button" class="button-inner green" (click)="backToSetting()">
          <span class="text">
            Manage your email preferences
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="content-img">
    <img src="/assets/images/email.svg" alt="image">
  </div>
</div>
